import { useTypedRouteLoaderData as useRouteLoaderData } from 'remix-typedjson';
import type { loader } from '~/root';

import type { getAccount } from '~/data/account/api/getAccount';

export function useAccount(): Awaited<ReturnType<typeof getAccount>>['data'] | null {
  const root = useRouteLoaderData<typeof loader>('root');
  if (root?.account.data) {
    return root.account.data;
  }
  return null;
}
